body {
    background-color: var(--y-lightgray);
}

.content {
    color: var(--y-black);
    font-family: var(--ui-font);
    font-size: 18px;
    text-align: left;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}

.content h1 {
    font-family: var(--brand-font);
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 30px;
}

.contentBox {
    background-color: var(--y-white);
    padding: 30px;
    margin-bottom: 30px;
}

.content a.button {
    text-decoration: none;
    text-align: center;
}

.content button, .content a.button {
    width: auto;
    padding: 7px 18px;
    background-color: var(--y-white);
    font-family: var(--brand-font);
    font-weight: 500;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid var(--y-black);
    color: var(--y-black);
}

.content a.button[disabled], .content button[disabled] {
    opacity: 0.5;
}

.fields {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr min-content 200px;
    /* label, input, button, message */
    row-gap: 28px;
    column-gap: 20px;
    font-size: 18px;
}

.fields .fillRow {
    display: flex;
    align-items: center;
}

.fields .spanColumns {
    grid-column: 2/-1;
}

.fields .fillRow input, .fields .fillRow textarea {
    flex: 1;
    border: 0;
    background: var(--y-lightgray);
    padding: 8px;
    font-size: 18px;
    font-family: var(--ui-font);
}

.fields .fillRow input:focus, .fields .fillRow textarea:focus {
    background: var(--y-white);
}

.content .formLabel {
    font-weight: 600;
    font-size: 18px;
}

.content input[type='checkbox'] {
    margin-right: 12px;
}

.imageHolder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.avatar {
    border: 1px solid var(--y-medgray);
}

.message {
    font-size: 14px;
    font-weight: 600;
    color: var(--y-purple);
}

.warning {
    background: var(--y-yellow);
    font-weight: 600;
    padding: 1em;
}

.content .back {
    display: block;
    color: var(--y-purple);
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 24px;
}

.checkboxLabel {
    display: block;
}

.fields button.logout {
    margin-top: 24px;
}
/* For smaller than 1000px */

@media (max-width: 1000px) {
    body {
        width: 100%;
    }
    .content {
        padding: 0 20px;
    }
    .content .back {
        font-size: 14px;
    }
    .content h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }
    .contentBox {
        padding: 20px;
    }
    .fields {
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 0;
    }
    .fields .formLabel:not(:first-child) {
        margin-top: 24px;
    }
    .fields .formLabel {
        margin-bottom: 12px;
    }
    .fields button {
        margin-top: 12px;
    }
    .fields .fillRow textarea {
        min-width: 260px;
        min-height: 80px;
    }
    input.username {
        max-width: 164px
    }
    .formLabel {
        margin-top: 8px;
    }
}