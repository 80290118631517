.content {
  max-width: 500px;
  margin: 88px auto;
  color: var(--y-black);
  font-family: var(--ui-font);
  font-size: 18px;
  text-align: left;
}

.content h1 {
  font-family: var(--brand-font);
  font-size: 28px;
  font-weight: 500;
  text-align: left;
  margin-top: 0;
  margin-bottom: 36px;
}

.formContainer {
  background: var(--y-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  padding: 2em;
  margin-bottom: 20px;
}

.formContainer .field {
  display: flex;
  flex-flow: column nowrap;
  width: calc( 100% - 18px );
}

.formContainer .fieldLabel {
  margin-bottom: 4px;
}

.formContainer form {
  display: block;
  width: 100%;
  height: auto;
}

.formContainer label {
  margin: 0 0 20px 0;
  font-size: 14px;
  font-weight: 500;
}

.formContainer input {
  font-family: var(--ui-font);
  font-size: 21px;
  width: 100%;
  height: 36px;
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid var(--y-darkgray);
  box-shadow: none;
}

.formContainer button {
  width: 100%;
  padding: 12px 20px;
  margin-top: 20px;
  margin-bottom: 32px;
  background-color: var(--y-purple);
  font-family: var(--brand-font);
  font-weight: 500;
  font-size: 22px;
  border-radius: 4px;
  border: 1px none var(--y-black);
  color: var(--y-white);
}

.message {
  text-align: left;
  padding: 8px;
  margin: 0 0 32px 0;
  font-weight: 500;
  background-color: var(--y-yellow);
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
  .content {
    margin-top: 40px;
    margin-bottom: 0;
    padding: 0;
  }
  .content h1 {
    font-family: var(--brand-font);
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 24px;
  }
  
  .message {
    padding: 0 20px;
    margin: 0;
  }
  .formContainer>* {
    background: var(--y-white);
    width: 100%;
  }

  .formContainer button {
    margin-top: 20px;
    margin-bottom: 24px;
  }

  
}