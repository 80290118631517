.userTitle {
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0;
}

a.userTitle .name {
  border-bottom: 1px solid #B2B2A3;
  line-height: 130%;
}
a.userTitle .name:hover {
  color: var(--y-purple);
  outline: none;
  border-bottom: 1px solid var(--y-purple);
}
a.userTitle .name:active {
  color: var(--y-darkpurple);
  outline: none;
  border-bottom: 1px solid var(--y-darkpurple);
}

.userTitle img {
  margin-right: .5em;
  margin-bottom: .1em;
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 1.5em;
}

.userTitle .name {
  margin-right: .5em;
}
