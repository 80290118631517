.warning {
  background: var(--y-yellow);
  color: var(--y-black);
  padding: 1em;
  text-align: center;
  font-family: var(--ui-font);
  font-size: 16px;
  font-weight: 400;
}

.warning button {
  background-color: var(--y-white);
  border: 1px solid var(--y-black);
  color: var(--y-black);
  border-radius: 4px;
  font-family: var(--ui-font);
  font-weight: 400;
  margin: 4px 4px;
}
.warning button:hover {
  background-color: var(--y-lightgray);
}
.warning button:active {
  background-color: var(--y-lightgray);
}


.pageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

.errorContainer {
  background: var(--y-white);
  padding: 1em 2em;
}
