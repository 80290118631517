.Works {
    color: var(--y-black);
    font-family: var(--ui-font);
    font-size: 21px;
    text-align: left;
    touch-action: manipulation;
    padding-bottom: 12px;
    /* Disable double-tap to zoom on mobile browsers */
}

.promptInstructions {
    background-color: var(--y-yellow);
}

.content {
    max-width: 960px;
    margin: 0 auto;
}

.headerTop {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.authorName {
    margin: 0 6px 12px 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    overflow-wrap: break-word;
}

.challengeTitle {
    margin-right: 0.5em;
}

.sketch img, .sketch canvas {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    background: var(--y-white);
    cursor: pointer;
}

.content h2 {
    font-family: var(--brand-font);
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 40px;
}

.tabs {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 16px;
}

.tab {
    font-size: 24px;
    font-weight: 500;
    font-family: var(--brand-font);
    padding-bottom: 4px;
    margin-left: 24px;
    border: 0;
    border-bottom: 1px solid white;
    color: var(--y-darkgray);
    background: none;
    outline: none;
}

.tab:hover {
    background: none;
}

.selected {
    border-bottom: 1px solid var(--y-black);
    color: var(--y-black);
}

.sketchHolder {
    background: var(--y-white);
    padding: 0;
    margin: 24px 21px 24px 0;
    width: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.belowSketch {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 8px;
}

.tag {
    background-color: var(--y-lightgray);
    color: var(--y-darkgray);
    font-family: var(--ui-font);
    font-size: .8em;
    text-align: center;
    padding: 0 .4em;
    display: inline-block;
    font-weight: 700;
    white-space: nowrap;
    align-self: flex-end;
    margin-top: 2px;
    margin-bottom: 2px;
}

.hideShowContainer {
    position: relative;
}

.hideShowContainer .toggleButton {
    position: absolute;
    right: 0;
    top: 0;
    height: 2em;
    width: 2em;
    background-size: 1.5em;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: 0;
    opacity: 0.6;
    transition: opacity .5s ease;
}

.hideShowContainer .toggleButton:hover {
    opacity: 1;
}

.toggleButton.hide {
    background-image: url(../../assets/icon-hide.svg);
}

.toggleButton.show {
    background-image: url(../../assets/icon-show.svg);
}

.hideShowContainer .tooltip {
    position: absolute;
    right: 0;
    top: 0;
    padding: 6px 10px;
    background: #000;
    border-radius: 4px;
    transform: translate(1em, 2.5em);
    color: var(--y-white);
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease, visibility .5s ease;
    font-weight: 500;
}

.hideShowContainer .tooltip::after {
    content: '';
    position: absolute;
    top: 0;
    right: 1.5em;
    transform: translate(0, -100%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid transparent;
    border-bottom: 6px solid #000;
}

.toggleButton:hover+.tooltip, .tooltip:hover {
    opacity: 1;
    visibility: visible;
}

.toggleButton:active, .toggleButton:focus {
    outline: none;
    border: none;
}

.pagingButtons {
    font-size: 16px;
}

.pagingIndexCount {
    margin: 0 20px;
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
    .Works {
        font-size: 14px;
        padding: 0 0 20px 0;
    }
    .content {
        padding: 0;
        margin: 0;
    }
    .headerTop {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .Works h1 {
        font-size: 24px;
        margin-bottom: 0;
    }
    .Works h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }
    .tabs {
        margin-top: 12px;
        font-size: 18px;
    }
    .tab {
        margin-left: 0;
        margin-right: 20px;
        padding: 0 0 4px 0;
        font-size: 18px;
    }
    h2.promptTitle {
        padding-top: 0;
        margin-bottom: 12px;
    }
    .sketchHolder {
        margin: 16px 12px 16px 0;
        flex-flow: column wrap;
    }
    .tag {
        font-size: 10px;
    }
    .authorName {
        font-size: 12px;
    }
}