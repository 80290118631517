.content {
  max-width: 500px;
  margin: 88px auto;
  color: var(--y-black);
  font-family: var(--ui-font);
  font-size: 18px;
  text-align: left;
}

.content h1 {
  font-family: var(--brand-font);
  font-size: 28px;
  font-weight: 500;
  text-align: left;
  margin-top: 0;
  margin-bottom: 36px;
}

.content a {
  color: var(--y-black);
  text-decoration: underline;
}
.content a:hover, a:active {
  color: var(--y-purple);
}

.formContainer {
  background: var(--y-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  padding: 2em;
  margin-bottom: 20px;
}

.formContainer .field {
  display: flex;
  flex-flow: column nowrap;
  width: calc( 100% - 18px );
}

.formContainer .fieldLabel {
  margin-bottom: 4px;
}

.formContainer form {
  display: block;
  width: 100%;
  height: auto;
  /* flex-direction: column;
  align-items: space-between;
  justify-content: space-between; */
}

.formContainer h3.centerline {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px 0;
  text-align: center;
  width: 100%;
  position: relative;
}

.formContainer h3.centerline:before {
  content: "";
  display: block;
  border-top: solid 1px var(--y-medgray);
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.formContainer h3.centerline span {
  background: var(--y-white);
  padding: 0 20px;
  position: relative;
  z-index: 5;
}

.formContainer label {
  margin: 0 0 20px 0;
  font-size: 14px;
  font-weight: 500;

}

.formContainer input {
  font-family: var(--ui-font);
  font-size: 21px;
  width: 100%;
  height: 36px;
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid var(--y-darkgray);
  box-shadow: none;
}

.checkboxLabel {
  display: flex;
  align-items: flex-start;
}

.marketingMessage {
  display: inline-block;
  font-weight: 400;
  margin-top: 2px;
  vertical-align: text-top;
}

.checkboxLabel input[type=checkbox] {
  margin-left: 0;
  margin-right: 12px;
  width: 16px;
  height: 16px;
}

.formContainer .legalMessage {
  font-size: 12px;
  padding-top: 4px;
  margin-bottom: 4px;
}

.formContainer button {
  width: 100%;
  padding: 12px 20px;
  margin-top: 20px;
  margin-bottom: 32px;
  background-color: var(--y-purple);
  font-family: var(--brand-font);
  font-weight: 500;
  font-size: 22px;
  border-radius: 4px;
  border: 1px none var(--y-black);
  color: var(--y-white);
}

.formContainer .googleButton {
  background-color: var(--y-white);
  color: var(--y-black);
  border: 1px solid var(--y-black);
  background-image: url(../../assets/logo_googleg_48dp.svg);
  background-repeat: no-repeat;
  background-position: left 14px center;
  padding-left: 50px;
}

.formContainer .underlineLink {
  font-weight: 700;
  color: var(--y-black);
  border-bottom: 1px solid var(--y-black);
  cursor: pointer;
  text-decoration: none;
}

.formContainer .underlineLink:hover {
  font-weight: 700;
  color: var(--y-purple);
  border-bottom: 1px solid var(--y-purple);
  cursor: pointer;
}

.formContainer Link {
  padding-top: 40px;
}

.message {
  text-align: left;
  padding: 8px;
  margin: 0 0 32px 0;
  font-weight: 500;
  background-color: var(--y-yellow);
}

.pageMessage {
  text-align: center;
  background: var(--y-white);
  max-width: 960px;
  margin: 80px auto;
  padding: 40px;
  line-height: 130%;
}

.pageMessage h2 {
  font-family: var(--brand-font);
  font-size: 24px;
  font-weight: 500;
}

.pageMessage p {

}

/* For smaller than 1000px */

@media (max-width: 1000px) {
  .content {
    margin-top: 40px;
    margin-bottom: 0;
    padding: 0;
  }
  .content h1 {
    font-family: var(--brand-font);
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 24px;
  }
  
  .message {
    padding: 0 20px;
    margin: 0;
  }
  .formContainer>* {
    background: var(--y-white);
    width: 100%;
  }
  
  .formContainer button {
    margin-top: 20px;
    margin-bottom: 24px;
  }
}