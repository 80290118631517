.landing {
    text-align: center;
    background-color: var(--y-lightgray);
    color: var(--y-black);
    text-align: center;
    font-family: var(--ui-font);
    font-size: 20px;
}

.landing p {
    line-height: 130%;
}

.landing a {
    color: var(--y-black);
    text-decoration: none;
}

.landing a:hover, a:active {
    color: var(--y-purple);
}

.separatorDash {
    height: 10px;
    border-style: solid none;
    border-width: 1px;
    border-color: var(--y-darkgray);
    margin-top: 40px;
    margin-bottom: 0;
}

/* HEADER */

.landing header {
    background-color: var(--y-white);
}

.headerBox {
    width: 960px;
    /* max-height: 504px; */
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.logo {
    width: 282px;
    height: 144px;
    margin: 40px 0 36px 0;
    pointer-events: none;
    order: 1;
}

.landing .headerBox h1 {
    font-family: var(--brand-font);
    font-size: 34px;
    font-weight: 500;
    text-align: left;
    width: 880px;
    margin-bottom: 36px;
    order: 3;
}

.buttonHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 2;
}

.signupTop {
    margin-top: 30px;
}

.startchallengeTop {
    min-width: 240px;
    margin-top: 30px;
}

.productHunt {
    width: 960px;
    /* max-height: 504px; */
    padding-bottom: 12px;
    margin: 0 auto;
    text-align: left;
}

.landing button {
    background-color: var(--y-purple);
    font-family: var(--brand-font);
    font-size: 28px;
    font-weight: 500;
    color: var(--y-white);
    width: 175px;
    height: 60px;
    padding-bottom: 3px;
    border: none;
    border-radius: 4px;
    outline: none;
}

.landing button:hover {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
}

.landing button:active {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
    border-color: var(--y-darkpurple);
}

.loginLink {
    font-size: .8em;
}

.loginLink a {
    font-weight: 700;
    border-bottom: 1px solid var(--y-black);
}

.loginLink a:hover {
    border-bottom: 1px solid var(--y-purple);
}

.content {
    width: 960px;
    margin: 0 auto;
}

/* EXAMPLE DRAWINGS */

.examples {
    background: rgb(243, 243, 235);
    background: linear-gradient(0deg, rgba(243, 243, 235, 1) 30%, rgba(255, 255, 255, 1) 100%);
    margin-top: 0;
    padding-top: 20px;
}

.exampleDrawings {
    margin: 0 auto;
    width: 86%;
    max-width: 1200px;
    margin-bottom: 20px;
}

.quickDraw {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.quickDraw h2 {
    width: 44%;
}

.quickDraw p {
    width: 56%;
    text-align: left;
    margin-top: 36px;
}

/* GIVE IT A TRY */

.sampleBox {
    margin-top: 40px;
    background-color: var(--y-purple);
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.sampleBox h3 {
    margin-bottom: 30px;
}

.sampleLayout {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.promptInstructions {
    background-color: var(--y-yellow);
    color: var(--y-black);
}

.sampleText {
    padding-right: 40px;
    padding-left: 40px;
}

.sampleBox p {
    text-align: left;
    color: var(--y-white);
}

.sampleBox h2 {
    margin-top: -60px;
    color: var(--y-white);
}

.sampleBox h5 {
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 0;
    color: var(--y-white);
}

.sampleSketch {
    width: auto;
    height: 100%;
    margin-bottom: 20px;
}

.sketch canvas {
    margin-right: 40px;
    width: 500px;
    height: 500px;
    border: none;
    background-color: var(--y-white);
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.sketch button {
    margin-right: 40px;
    width: 100px;
    height: 36px;
    font-size: 18px;
    background-color: var(--y-white);
    border: 1px solid var(--y-black);
    color: var(--y-black);
}

.sketch button:nth-child(3n+3) {
    /* hide the button to submit */
    display: none;
}

/* HOW IT WORKS */

.howItWorksBox {
    margin-top: 60px;
    background-color: var(--y-white);
    padding-bottom: 60px;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.topDash {
    border-style: dashed none none none;
    border-width: 1px;
    border-color: var(--y-darkgray);
    margin-top: 14px;
}

.landing h3 {
    font-family: var(--brand-font);
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    color: var(--y-black);
    text-transform: uppercase;
    letter-spacing: 0.12em;
    padding: 52px 0 30px 0;
}

.landing h2 {
    font-family: var(--brand-font);
    font-size: 34px;
    font-weight: 500;
    text-align: left;
}

.landing h4 {
    font-family: var(--brand-font);
    font-size: 28px;
    font-weight: 500;
    text-align: left;
}

.landing h4 a {
    border-bottom: 1px solid var(--y-black);
}

.landing h4 a:hover {
    border-bottom: 1px solid var(--y-purple);
}

.step {
    display: flex;
    width: 850px;
    margin: 50px auto;
    align-items: flex-start;
}

.step h2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.howItWorksBox h4 {
    text-align: center;
}

.howItWorksBox p {
    text-align: left;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 40px;
}

.howItWorks1 {
    width: 200px;
    height: 170px;
}

.howItWorks2 {
    width: 200px;
    height: 170px;
}

.howItWorks3 {
    width: 200px;
    height: 170px;
}

.stepText {
    width: 50%;
    order: 2;
}

.stepImage {
    width: 28%;
    order: 3;
}

.circle {
    width: 100px;
    padding: 0 20px;
    order: 1;
}

.stepImageReverse {
    order: 1;
}

/* MOBILE ONLY STYLES */

.landing button.openModal {
    /* "Open drawing tool" button that only appears on mobile devices */
    background-color: var(--y-white);
    font-size: 28px;
    border: 1px solid var(--y-black);
    color: var(--y-black);
    min-width: 280px;
    width: auto;
    padding: 0 30px 3px 30px;
    height: 60px;
    padding: auto;
    border-radius: 4px;
    margin: 0 40px 40px 40px;
}

.landing button.openModal:hover {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
}

.landing button.openModal:active {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
    border-color: var(--y-darkpurple);
}

.modal {
    position: absolute;
    top: '50%';
    left: '50%';
    right: 'auto';
    bottom: 'auto';
    margin-right: '-50%';
    transform: 'translate(-50%, -50%)';
    width: 100%;
    height: 100%;
    background-color: var(--y-lightgray);
    padding-top: 40px;
    outline: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--y-lightgray);
    margin: 0 auto;
    text-align: center;
}

.overlaySketchBox {
    width: auto;
}

.overlay button {
    width: auto;
    height: 36px;
    font-size: 18px;
    font-family: var(--brand-font);
    background-color: var(--y-white);
    border: 1px solid var(--y-black);
    color: var(--y-black);
    text-align: center;
    border-radius: 4px;
    margin-bottom: 20px;
}

.overlay button:nth-child(3n+3) {
    /* hide the button to submit */
    display: none;
}

.overlay button:hover {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
}

.overlay button:active {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
    border-color: var(--y-darkpurple);
}

/* CHALLENGE LIBRARY */

.categories {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.categories p {
    width: 30%;
    font-family: var(--brand-font);
    font-size: 34px;
    font-weight: 500;
    color: var(--y-darkgray);
}

.categories p.currentlyActive {
    color: var(--y-black);
}

/* TESTIMONIALS */

.testimonialBox h3 {
    margin-top: 12px;
    margin-bottom: 0;
}

.quotes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: baseline;
}

.quote {
    font-family: var(--brand-font);
    font-weight: 400;
    color: var(--y-black);
    width: 40%;
    padding: 0 20px;
}

.quoteText {
    font-size: 26px;
    margin-bottom: 12px;
}

.quoteTextSmall {
    font-size: 22px;
    margin-bottom: 12px;
}

.quoteName {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 0;
}

.quoteCaption {
    font-size: 16px;
    margin-top: 0;
}

/* LOGOS */

.logoGrid {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 0;
}

.logo {
    width: 220px;
    height: 88px;
    border: none;
    outline: none;
}

/* COMING SOON */

.comingSoonBox {
    background-color: var(--y-white);
    padding: 0 60px 68px 60px;
    margin: 40px 0 80px 0;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.comingSoonBox h3 {
    padding-top: 68px;
}

.comingSoonBox button {
    width: 285px;
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
    .content {
        width: 100%;
    }
    /* HEADER */
    .headerBox {
        width: 100%;
        max-height: none;
        padding: 0 0 40px 0;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .logo {
        width: 205px;
        height: 105px;
        text-align: center;
        order: 1;
        margin: 40px 0 20px 0;
    }
    .signupTop {
        margin-top: 0;
    }
    .landing .headerBox h1 {
        width: 90%;
        font-size: 28px;
        text-align: center;
        margin-bottom: 20px;
        order: 2;
    }
    .buttonHolder {
        text-align: center;
        order: 3;
    }
    .productHunt {
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
    }
    /* EXAMPLES */
    .exampleDrawings {
        width: 95%;
        margin-bottom: 20px;
    }
    .quickDraw {
        display: block;
    }
    .quickDraw h2 {
        width: auto;
        text-align: center;
        font-size: 28px;
        margin: 20px 20px;
    }
    .quickDraw p {
        width: auto;
        text-align: center;
        margin: 20px 20px;
    }
    /* HOW IT WORKS */
    .step {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 50px 0;
        align-items: center;
        justify-content: center;
    }
    .stepImage {
        width: 80%;
        order: 1;
    }
    .stepText {
        width: 80%;
        order: 3;
    }
    .circle {
        width: 70px;
        height: 70px;
        margin: 40px 0 0 0;
        order: 2;
    }
    .landing h4 {
        text-align: center;
        margin: 0 50px;
    }
    .howItWorksBox p {
        text-align: center;
        margin-bottom: 0;
    }
    .howItWorksBox h2 {
        text-align: center;
    }
    /* GIVE IT A TRY */
    .sampleLayout {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sampleText {
        text-align: center;
        width: 80%;
        padding: 0 0 20px 0;
    }
    .sampleText h2 {
        text-align: center;
    }
    .sampleBox p {
        text-align: center;
    }
    .sampleBox h2 {
        margin-top: 0;
    }
    .sampleBox h5 {
        text-align: center;
    }
    .sampleSketch {
        width: auto;
        height: auto;
    }
    .sketch {
        width: auto;
        height: auto;
    }
    .sketch canvas {
        margin: 0;
    }
    .sketch button {
        margin: 0;
    }
    /* CHALLENGE LIBRARY */
    .categories p {
        width: 40%;
        font-size: 24px;
    }
    /* TESTIMONIALS */
    .quotes {
        flex-flow: column nowrap;
        align-items: center;
    }
    .quote {
        width: auto;
        max-width: 500px;
        margin: 16px;
        font-size: 28px;
    }
    .quoteText, .quoteTextSmall {
        font-size: 21px;
    }
    .quoteName {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 0;
    }
    /* LOGOS */
    .logoGrid {
        flex-flow: column nowrap;
    }
    .logo {
        width: 154px;
        height: 62px;
        margin: 20px 0;
    }
    /* COMING SOON */
    .comingSoonBox h2 {
        text-align: center;
    }
    .comingSoonBox button {
        width: 175px;
    }
}