body {
    background-color: var(--y-lightgray);
}

.content {
    color: var(--y-black);
    font-family: var(--ui-font);
    font-size: 21px;
    font-weight: 400;
    text-align: left;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.contentBox {
    background-color: var(--y-white);
    padding: 36px 40px;
    margin-top: 12px;
    margin-bottom: 20px;
}

.spaceBelow {
    margin-bottom: 40px;
}

.contentBoxMinimize {
    background-color: var(--y-white);
    padding: 32px 40px 4px 40px;
    margin-bottom: 0;
}

.contentBoxMinimize .challengeTop>* {
    font-size: .9em;
}

/* 
 * Select all but the first .contentBoxMinimize child of .home,
 * and remove the border from the previous rule.
 */

.content>.contentBoxMinimize~.contentBoxMinimize {
    padding: 0 40px 4px 40px;
}

.content .welcome p {
    font-size: 14px;
    margin-right: 40px;
}

.content h1 {
    font-family: var(--brand-font);
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 20px;
}

.content h1 a {
    text-decoration: none;
    color: var(--y-black);
    border-bottom: 1px solid var(--y-black);
}

.welcome {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.avatarLink {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    text-decoration: none;
}

.avatar img {
    border: 1px solid var(--y-medgray);
    width: 92px;
    height: 92px;
}

.editMe {
    background-color: var(--y-yellow);
    color: var(--y-purple);
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    border-bottom: none;
    text-align: center;
}

.content h2 {
    font-family: var(--brand-font);
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    padding-top: 8px;
    margin-bottom: 24px;
}

.content .headerLayout {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.content .headerLayout h2 {
    margin-top: 0;
    margin-bottom: 16px;
}

.content .challengeTop {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.content .kicker {
    font-weight: 700;
    color: var(--y-purple);
    text-decoration: none;
    padding: 0;
    margin: 0 0 16px 0;
    font-size: 18px;
}

.content .dayProgress {
    color: var(--y-purple);
    margin: 0;
    padding: 0;
    font-size: 18px;
}

.content h3 {
    display: block;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    margin-top: 16px;
}

.productHunt {
    margin-top: 52px;
    margin-left: 20px;
}


/*** Category above header ***/

.headerEyebrow {
    display: block;
    font-family: var(--ui-font);
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    margin-top: 24px;
    margin-bottom: 8px;
    padding: 0;
    color: var(--y-purple);
}

/****  Daily play styles  ****/

.dailyPlayHeader {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 24px;
}

.dailyPlayHeader h2 {
    padding-right: 20px;
    margin-top: 0;
    margin-bottom: 0;
}

.dailyPlayHeader .smallInfo {
    font-size: 14px;
    font-weight: 500;
    padding-right: 20px;
}

.dailyPlayHeader .countdown {
    flex: 1;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: var(--y-darkgray);
    white-space:nowrap;
}

.dailyPlayHeader .countdown em {
    outline: 1px solid var(--y-medgray);
    font-style: normal;
    padding: 1px 2px;
    margin-left: 2px;
}

.dailyPlayHeader .smallLink {
    display: inline;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: var(--y-purple);
    margin-right: 8px;
}

.dailyPlayCards {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 16px;
    padding-bottom: 12px;
}

.dailyPlayCards .contentBox {
    padding: 8px 30px 20px 30px;
    margin: 0;
}

.dailyPlayCards .contentBox .fillCardContents {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
    font-size: 16px;
}

.dailyPlayCards .smallEyebrow {
    color: var(--y-purple);
    font-size: 16px;
    margin: 12px 0 0 0;
}

.dailyPlayCards h3 {
    font-family: var(--brand-font);
    font-size: 24px;
    font-weight: 400;
    margin: 12px 0 20px 0;
}

.dailyPlayCards .reviewDayBox {
    display: grid;
    grid-template-columns: auto 196px;
    gap: 20px;
}

.dailyPlayCards .reviewDayBox img {
    box-shadow: 0 2px 5px #0002;
}

.dailyPlayCards .thumbnailGallery img:nth-child(1) {
    height: 130px;
    grid-area: one;
}

.dailyPlayCards .thumbnailGallery img:nth-child(2) {
    height: 60px;
    grid-area: two;
}

.dailyPlayCards .thumbnailGallery img:nth-child(3) {
    height: 60px;
    grid-area: three;
    align-self: end;
}

.dailyPlayCards .thumbnailGallery {
    margin-top: 12px;
    margin-left: 0;
    display: grid;
    grid-template-columns: 130px 60px;
    grid-gap: 8px 12px;
    grid-template-areas: "one two" "one three";
    height: 130px;
}

.content .challengeLayout {
    /* display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start; */
    display: block;
}

.content .challengeLayout:after {
    content: "";
    display: table;
    clear: both;
}

.content .challengeMessage {
    width: 50%;
    float: left;
    font-size: 16px;
}

.content .challengeMessage {
    margin-top: 0;
    margin-bottom: 40px;
}

.content .challengeMembers {
    display: inline-block;
}

.content .challengeButtons {
    display: flex;
    flex-flow: column wrap;
    width: 340px;
    min-height: 130px;
    float: right;
}

.content button {
    width: 340px;
    margin-bottom: 24px;
}

.content .contentBoxLayout {
    display: flex;
    flex-flow: row no-wrap;
}

.content .contentBoxLayout h3 {
    margin-top: 10px;
}

.content .instructions {
    padding-right: 20px;
    flex-shrink: 1000;
    font-size: 16px;
}

.challengeImage {
    padding: 0 40px 0 20px;
}

.challengeImage img {
    width: 160px;
    height: 136px;
}

.avatar, .avatarLarge {
    cursor: pointer;
}

.avatarLarge {
    padding: 0 40px 0 20px;
}

.avatarLarge img {
    width: 160px;
    height: 160px;
    border: 1px solid var(--y-medgray);
}

.content button.mediumBlackOutline {
    width: auto;
    height: 36px;
    padding: 4px 20px;
    background-color: var(--y-white);
    font-family: var(--brand-font);
    font-weight: 500;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid var(--y-black);
    color: var(--y-black);
    margin: 16px 0 0 0;
    clear: both;
}

.content button.mediumBlackOutline:hover {
    outline: none;
    border-color: var(--y-purple);
    color: var(--y-purple);
}

.content button.mediumBlackOutline:focus {
    outline: none;
    border-color: var(--y-black);
    color: var(--y-black);
}

.content button.mediumBlackOutline:active {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
    border-color: var(--y-darkpurple);
}

/* Modal styles */

.shareModal {
    position: fixed;
    z-index: 500;
    background-color: var(--y-white);
    width: 500px;
    min-height: 195px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    display: flex;
    flex-flow: column wrap;
}

.closeButton {
    position: fixed;
    top: 12px;
    right: 16px;
}

.closeButton .icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.content .shareMessage {
    line-height: 140%;
}

.content .challengeLinkHolder {
    display: flex;
    flex-flow: row nowrap;
}

.content .challengeLink {
    font-family: var(--ui-font);
    font-weight: 500;
    font-size: 14px;
    color: var(--y-black);
    background-color: var(--y-lightgray);
    border: none;
    width: 100%;
    height: 26px;
    padding: 4px;
    margin-bottom: 12px;
}

.content .challengeLink:focus {
    border: none;
    outline: none;
}

.content button.copyLink {
    width: auto;
    height: 36px;
    padding: 0 18px;
    margin: 0 0 0 8px;
    background-color: var(--y-white);
    font-family: var(--brand-font);
    font-weight: 500;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid var(--y-black);
    color: var(--y-black);
}

.content button.copyLink:hover {
    border: 1px solid var(--y-purple);
    color: var(--y-purple);
    background-color: var(--y-white);
}

.content button.copyLink:active {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
    border: 1px solid var(--y-darkpurple);
}

.content .copyMessage {
    padding: 0 0 8px 0;
    font-size: 14px;
    font-weight: 500;
    color: var(--y-purple);
}

.newChallengeModal {
    position: fixed;
    z-index: 500;
    background-color: var(--y-white);
    width: 960px;
    height: auto;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    display: flex;
    flex-flow: column wrap;
}

.newChallengeModal .closeButton {
    position: fixed;
    top: 22px;
    right: 26px;
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
    body {
        width: 100%;
    }
    .avatar img {
        width: 60px;
        height: 60px;
    }
    .content {
        margin: 64px 0;
        padding: 0 20px;
        font-size: 16px;
    }
    .content p {
        font-size: 14px;
    }
    .content .welcome p {
        font-size: 14px;
    }
    .content .headerLayout {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .content .headerLayout h2 {
        margin-bottom: 12px;
    }
    .content .headerLayout button {
        margin-bottom: 12px;
    }
    .content .challengeTop {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .content .challengeLayout {
        flex-flow: column wrap;
    }
    .content .challengeMessage {
        width: 100%;
        margin-bottom: 20px;
    }
    .content .challengeMessage {
        margin-bottom: 0;
    }
    .content .challengeButtons {
        width: 100%;
        max-width: 300px;
        padding-top: 12px;
        margin-right: 0;
        margin-bottom: 20px;
        align-items: left;
        float: none;
        min-height: 0;
    }
    .content .challengeButtons:after {
        content: "";
        display: table;
        clear: both;
    }
    .content button {
        width: auto;
        max-width: 300px;
        padding: 0 30px;
        margin-bottom: 16px;
        font-size: 24px;
        height: 52px;
    }
    .content .challengeMembers {
        width: calc( 100% + 20px);
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding: 0;
        margin: 0;
    }
    .contentBox {
        padding: 20px;
    }
    .challengeTop .kicker {
        margin-bottom: 4px;
    }
    .contentBoxMinimize {
        background-color: var(--y-white);
        padding: 20px;
        margin-bottom: 0;
    }
    .content>.contentBoxMinimize~.contentBoxMinimize {
        padding: 0 20px 28px 20px;
    }
    .contentBoxMinimize:last-of-type {
        margin-bottom: 20px;
    }
    .content h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }
    .content h2 {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .content h3 {
        margin-top: 24px;
    }
    .content .contentBoxLayout {
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: space-between;
    }
    .instructions {
        width: 50%;
    }
    .challengeImage {
        padding: 0 0 20px 0;
    }
    .challengeImage img {
        width: 120px;
        height: 102px;
    }
    .content button {
        margin-top: 0;
        width: 100% !important;
    }
    .avatarLarge {
        padding: 0 0 20px 0;
    }
    .avatarLarge img {
        width: 100px;
        height: 100px;
    }
    .productHunt {
        margin: 4px 0 8px 0;
    }
    .shareModal {
        position: fixed;
        z-index: 500;
        width: 100%;
        max-height: 250px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #ccc;
        padding: 24px 40px;
        flex-flow: column nowrap;
    }
    .content .challengeLinkHolder {
        flex-flow: column;
        align-items: flex-start;
    }
    .content .challengeLink {
        width: 100%;
        max-width: 400px;
    }
    .content button.copyLink {
        font-size: 16px;
        margin: 0;
        padding: 4px 20px;
    }
    .content .copyMessage {
        padding-top: 8px;
    }
    .newChallengeModal {
        position: fixed;
        z-index: 500;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
    }
    .newChallengeModal .closeButton {
        top: 18px;
        right: 18px;
    }
    .headerEyebrow {
        font-size: 11px;
    }
    /****  Daily play styles  ****/
    .dailyPlayHeader h2 {
        margin-bottom: 8px;
    }
    .dailyPlayHeader .countdown {
        text-align: left;
        margin-top: 8px;
    }
    .dailyPlayCards {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: stretch;
    }
    .dailyPlayCards .contentBox {
        padding: 8px 20px 16px 20px;
        margin: 4px 0;
        flex-grow: 1;
    }
    .dailyPlayCards .contentBox .fillCardContents {
        flex-flow: column wrap;
        height: auto;
        justify-content: space-between;
    }
    .dailyPlayCards h3 {
        font-size: 22px;
    }
    .dailyPlayCards .smallEyebrow {
        margin: 12px 0 0 0;
        font-size: 14px;
    }
    .dailyPlayCards h3 {
        margin: 12px 0 20px 0;
    }
    .dailyPlayCards .reviewDayBox {
        display: flex;
        flex-flow: row wrap;
        flex-grow: 2;
    }
    .dailyPlayCards .thumbnailGallery {
        margin: 16px 0;
    }    
}

.updateNote {
    font-size: .8em;
    margin: -1em 0;
}