/* html, body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
footer {
  flex-shrink: 0;
} */

footer {
  background-color: var(--y-white);
  width: 100%;
  flex-shrink: 0;
}

.footerContent {
  display: flex;
  flex-direction: row;
  width: 960px;
  height: 80px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.footerText {
  display: flex;
  flex-direction: row;
}

footer p {
  font-size: 14px;
  margin-right: 24px;
}

.socialButton {
  margin-left: 10px;
}

footer a {
  color: var(--y-black);
  text-decoration: none;
}
footer a:hover, a:active {
  color: var(--y-purple);
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
  .footerContent {
    width: 100%;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .footerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 0 20px 20px;
  }
  footer p {
    margin: 0 0 20px 0;
    font-size: 14px;
  }
  .socialButtons {
    padding-bottom: 40px;
    padding-left: 20px;
  }
  .socialButton {
    margin-left: 0px;
    margin-right: 10px;
  }
}