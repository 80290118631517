body {
    background-color: var(--y-lightgray);
    overscroll-behavior-y: contain;
}

.Prompt {
    color: var(--y-black);
    font-family: var(--ui-font);
    font-size: 21px;
    text-align: left;
    /* On touch devices, will can fix the position like this and still register touches in the P5 sketch correctly,
      but will need to adjust the layout so it all fits on the screen */

    /* no-select */
    -webkit-touch-callout: none;  /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    touch-action: none;
}

.Mobile {
    position: fixed;
    width: 100%;
}

.PromptHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}

.PromptHeader a {
    text-decoration: none;
    font-weight: bold;
    margin-left: 20px;
    color: var(--y-black);
}

.promptInstructions {
    background-color: var(--y-yellow);
}

.content {
    max-width: 960px;
    margin: 0 auto;
}

.content h1 {
    font-family: var(--brand-font);
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
    flex-grow: 2;
}

.content h2 {
    font-family: var(--brand-font);
    font-size: 32px;
    font-weight: 400;
    text-align: left;
    padding-top: 20px;
    margin-bottom: 30px;
}

.tiny {
    font-size: 12px;
}

.sketchLayout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sketch {
    width: 500px;
}

.sketchText {
    width: 100%;
    padding-right: 60px;
    margin-bottom: 100px;
}

.sketch {
    /* no-select */
    -webkit-touch-callout: none;  /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.sketch canvas, .sketch img {
    margin-right: 40px;
    border: none;
    background-color: var(--y-white);
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.sketch .yourSubmissionCaption {
    padding-top: 18px;
}

.buttonHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
}

.sketch button {
    width: auto;
    height: 36px;
    padding: 0 18px;
    background-color: var(--y-white);
    font-family: var(--brand-font);
    font-weight: 500;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid var(--y-black);
    color: var(--y-black);
}

.promptState {
    width: 500px;
    text-align: right;
    font-size: 18px;
}
.forceDisplay {
    text-align: right;
}
.forceDisplay button {
    width: auto;
    height: auto;
    padding: 4px 18px;
    background-color: var(--y-white);
    font-family: var(--brand-font);
    font-weight: 500;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid var(--y-black);
    color: var(--y-black);
}

.submitMessage {
    font-size: .9em;
}

.link {
    color: var(--y-purple);
    font-weight: 700;
    text-decoration: none;
    font-size: .9em;
}
.link * {
    color: var(--y-purple);
    text-decoration: none;
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
    .content {
        padding: 0 20px;
    }
    .Prompt {
        font-size: 14px;
    }
    .Prompt h1 {
        font-size: 24px;
        margin-bottom: 0;
    }
    .content h2 {
        display: inline-block;
        font-size: 18px;
        padding-top: 0;
        margin: 8px 4px 0 0;
    }
    .promptInstructions {
        display: inline;
        margin-left: 4px;
    }

    .sketchLayout {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .sketch {
        width: auto;
        height: auto;
        margin: 0;
    }
    .sketchText {
        padding-right: 0;
        margin-bottom: 12px;
    }
    .sketch canvas, .sketch img {
        margin: 0;
        margin-left: 0px;
        /* hack to make the sketch "full screen" */
    }
    .sketch button {
        margin: 0;
        /* hack to make the sketch "full screen" */
        font-size: 16px;
    }
    .promptState {
        width: auto;
        text-align: left;
    }
    .forceDisplay {
        text-align: left;
    }
}