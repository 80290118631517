.header {
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--y-medgray);
  position: fixed;
  top: 0;left: 0;right: 0;
  background-color: var(--y-lightgray);
  z-index: 90;
  /* no-select */
  -webkit-touch-callout: none;  /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.topNav {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0;
}

.pageLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--y-black);
  font-family: var(--ui-font);
  font-size: 21px;
  text-align: left;
}

.pageLinks a {
  margin-left: 40px;
  text-decoration: none;
  font-size: 18px;
  color: var(--y-black);
  border-bottom: 1px transparent var(--y-black);
}

.pageLinks a:hover {
  border-bottom: 1px solid var(--y-black);
}

.pageLinks a:active {
  color: var(--y-purple);
  border-bottom: 1px solid var(--y-purple);
}

.pageLinks button {
  margin-left: 40px;
  width: auto;
  padding: 4px 12px;
  background-color: var(--y-white);
  font-family: var(--brand-font);
  font-weight: 500;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid var(--y-black);
  color: var(--y-black);
}

.logo {
  height: 44px;
}


/* For smaller than 1000px */

@media (max-width: 1000px) {
  .header {
      margin-bottom: 14px;
  }

  .topNav {
      height: 38px;
      padding: 0 20px;
  }

  .logo {
      height: 30px;
  }
  
  .pageLinks a {
      font-size: 14px;
      margin-left: 28px;
  }
}