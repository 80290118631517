.Sample {
    position: fixed;
    width: 100%;
    margin: 20px auto;

    color: var(--y-black);
    font-family: var(--ui-font);
    font-size: 21px;
    text-align: left;
    /* On touch devices, will can fix the position like this and still register touches in the P5 sketch correctly,
      but will need to adjust the layout so it all fits on the screen */

    /* no-select */
    -webkit-touch-callout: none;  /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    touch-action: none;
}

.Sample header {
    display: none !important;
}

.sketch {
    width: auto;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.sketch canvas {
    margin: 0;
}

.sketch button {
    margin: 0;
}

.sketch button {
    width: auto;
    height: 36px;
    font-size: 18px;
    font-family: var(--brand-font);
    background-color: var(--y-white);
    border: 1px solid var(--y-black);
    color: var(--y-black);
    text-align: center;
    border-radius: 4px;
    margin-bottom: 20px;

}

.sketch button:nth-child(3n+3) {
    /* hide the button to submit */
    display: none;
}


.sketch button:hover {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
}

.sketch button:active {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
    border-color: var(--y-darkpurple);
}
