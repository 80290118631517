.sendEmailDialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 90vw;
  padding: 2em;
  background: white;
  z-index: 100;
}

.sendEmailDialog p {
  font-weight: 600;
  margin-top: 0;
  font-size: 1.2em;
}

.sendEmailDialog h2 {
  padding-top: 0 !important;
  margin-top: 0;
}

.sendEmailDialog label {
  display: block;
  margin-bottom: 1.2em;
  font-size: 0.8em;
  font-weight: 500;
}

.sendEmailDialog input:not([type=checkbox]),
.sendEmailDialog textarea {
  font-family: var(--ui-font);
  display: block;
  width: 100%;
  padding: 1em;
  font-size: 1em;
  box-sizing: border-box;
  margin-top: 0.7em;
}

.sendEmailDialog input:is([type=checkbox]) {
  margin-right: 0.5em;
}