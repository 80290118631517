body {
    background-color: var(--y-lightgray);
    overscroll-behavior-y: contain;
}

.Prompt {
    color: var(--y-black);
    font-family: var(--ui-font);
    font-size: 21px;
    text-align: left;
    /* On touch devices, will can fix the position like this and still register touches in the P5 sketch correctly,
      but will need to adjust the layout so it all fits on the screen */

    /* no-select */
    -webkit-touch-callout: none;  /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    touch-action: none;
}

.Mobile {
    position: fixed;
    width: 100%;
}

.PromptHeader {
    display: flex column;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}

.promptInstructions {
    background-color: var(--y-yellow);
}

.content {
    max-width: 960px;
    margin: 0 auto;
}

.content .back {
    display: block;
    color: var(--y-purple);
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 24px;
}

.content h1 {
    font-family: var(--brand-font);
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
    flex-grow: 2;
}


.sketchLayout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sketch {
    width: 500px;
}

.sketchText {
    width: 100%;
    padding-right: 60px;
    margin-bottom: 100px;
}

.sketch {
    /* no-select */
    -webkit-touch-callout: none;  /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.sketch canvas {
    margin-right: 40px;
    width: 500px;
    height: 500px;
    border: none;
    background-color: var(--y-white);
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.buttonHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
}

.sketch button {
    width: auto;
    height: 36px;
    padding: 0 18px;
    background-color: var(--y-white);
    font-family: var(--brand-font);
    font-weight: 500;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid var(--y-black);
    color: var(--y-black);
}

.sketch .message {
    display: block;
    font-size: 16px;
    text-align: right;
}
/* For smaller than 1000px */

@media (max-width: 1000px) {
    .content {
        padding: 0 20px;
    }
    .content .back {
        font-size: 14px;
        margin-bottom: 8px;
    }
    .Prompt {
        font-size: 14px;
    }
    .content h1 {
        font-size: 24px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .PromptHeader p {
        padding-top: 0;
        margin-top: 8px;
        font-size: 16px;
    }
    
    .sketchLayout {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .sketch {
        width: auto;
        height: auto;
        margin: 0;
    }
    .sketchText {
        padding-right: 0;
        margin-bottom: 0;
    }
    .sketch canvas {
        margin: 0;
        margin-left: 0px;
        /* hack to make the sketch "full screen" */
    }
    .sketch button {
        margin: 0;
        height: 40px;
        padding: 0 12px;
        /* hack to make the sketch "full screen" */
        font-size: 16px;
    }
}