body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

[disabled] {
  pointer-events: none;
}

/* Nichrome 0.5 font */

@font-face {
  font-family: 'Nichrome';
  src: url('./assets/fonts/Nichrome0.5-Infra.woff2') format('woff2'), url('./assets/fonts/Nichrome0.5-Infra.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Nichrome';
  src: url('./assets/fonts/Nichrome0.5-Thin.woff2') format('woff2'), url('./assets/fonts/Nichrome0.5-Thin.woff') format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'Nichrome';
  src: url('./assets/fonts/Nichrome0.5-Light.woff2') format('woff2'), url('./assets/fonts/Nichrome0.5-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Nichrome';
  src: url('./assets/fonts/Nichrome0.5-Regular.woff2') format('woff2'), url('./assets/fonts/Nichrome0.5-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Nichrome';
  src: url('./assets/fonts/Nichrome0.5-Dark.woff2') format('woff2'), url('./assets/fonts/Nichrome0.5-Dark.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Nichrome';
  src: url('./assets/fonts/Nichrome0.5-Bold.woff2') format('woff2'), url('./assets/fonts/Nichrome0.5-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Nichrome';
  src: url('./assets/fonts/Nichrome0.5-Black.woff2') format('woff2'), url('./assets/fonts/Nichrome0.5-Black.woff') format('woff');
  font-weight: 800;
}

@font-face {
  font-family: 'Nichrome';
  src: url('./assets/fonts/Nichrome0.5-Ultra.woff2') format('woff2'), url('./assets/fonts/Nichrome0.5-Ultra.woff') format('woff');
  font-weight: 900;
}

.nichrome-infra, .nichrome-100 {
  font-family: 'Nichrome';
  font-weight: 100;
  font-kerning: normal;
}

.nichrome-thin, .nichrome-200 {
  font-family: 'Nichrome';
  font-weight: 200;
  font-kerning: normal;
}

.nichrome-light, .nichrome-300 {
  font-family: 'Nichrome';
  font-weight: 300;
  font-kerning: normal;
}

.nichrome, .nichrome-regular, .nichrome-400 {
  font-family: 'Nichrome';
  font-weight: 400;
  font-kerning: normal;
}

.nichrome-dark, .nichrome-500 {
  font-family: 'Nichrome';
  font-weight: 500;
  font-kerning: normal;
}

.nichrome-bold, .nichrome-700 {
  font-family: 'Nichrome';
  font-weight: 700;
  font-kerning: normal;
}

.nichrome-black, .nichrome-800 {
  font-family: 'Nichrome';
  font-weight: 800;
  font-kerning: normal;
}

.nichrome-ultra, .nichrome-900 {
  font-family: 'Nichrome';
  font-weight: 900;
  font-kerning: normal;
}

header+* {
  margin-top: 4.5em;
}

@media (max-width: 1000px) {
  header+* {
    margin-top: 3.5em;
  }
}

/* YDays CSS variables */

:root {
  /* Colors */
  --y-black: #191919;
  --y-white: #FFF;
  --y-lightgray: #F3F3EB;
  --y-medgray: #DADAD0;
  --y-darkgray: #848475;
  --y-purple: #6F6CF4;
  --y-darkpurple: #3D18A7;
  --y-yellow: #FCFF74;
  --y-cyan: #66F4EC;
  /* Typography */
  --brand-font: 'Nichrome', 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --ui-font: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
}