.progress th {
  text-align: center;
  font-weight: 600;
  font-size: .9em;
  padding-bottom: 8px;
}

.progress td {
  background-repeat: no-repeat;
  background-size: 1.2em;
  background-position: center center;
  min-height: 1.2em;
  min-width: 1.2em;
  padding: .2em 0.5em .2em 0;
  font-size: .9em;
  vertical-align: middle;
}

.progress .username {
  font-size: 18px;
  max-width: 250px;
  min-width: 150px;
}

.progress td.avatarCell {
  min-width: 28px;
  width: 28px;
  padding: 0;
  margin: 0;
}

.progress td.totals {
  text-align: right;
  font-size: 14px;
}

.progress img.avatar {
  margin-right: 8px;
}

.progress .submitted {
  background-image: url(../../assets/icon-check.svg);
}

.progress .skipped {
  background-image: url(../../assets/icon-cross.svg);
}

.progress .na {
  background-image: url(../../assets/icon-bar.svg);
}

.progress .day {
  text-align: right;
  padding-right: 8px;
}

.progress .dayLink {
  text-decoration: none;
  color: var(--y-purple);
}

.progress .showVotes {
  padding: .5em 0 .5em 4.5em;
  font-size: 14px;
}

.progress .host {
  display: static;
  vertical-align: middle;
  font-size: .65em;
  font-weight: 700;
  background-color: var(--y-yellow);
  padding: 2px 4px;
  margin-left: 2px;
}

.contentBox {
  background-color: var(--y-white);
  padding: 16px 40px 20px 40px;
  margin-bottom: 20px;
}

.contentBox h3.recognition {
  font-family: var(--brand-font);
  font-size: 24px;
  font-weight: 500;
  color: var(--y-black);
  margin-bottom: 20px;
}

.emojiAwards {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 20px;
}

.emojiSection {
  width: 100%;
}

.userLarge, .userMed {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
}

.avatarLargeHolder {
  position: relative;
  margin-right: 24px;
}

.avatarLargeHolder img {
  display: block;
}

.avatarLargeHolder .awardRibbon {
  position: absolute;
  top: 60px;
  right: -10px;
}

.avatarLargeHolder .awardRibbon:after {
  content: url('../../assets/badge-ribbon.svg');
}

.userLargeRightSide {
  padding-right: 32px;
}

.userLargeRightSide h4 {
  font-size: 21px;
  margin: 8px 0;
}

.userLargeRightSide p {
  margin: 0 0 8px 0;
  font-size: 16px;
  line-height: 1.2;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}

.attendanceAwards {
  display: grid;
  grid-template-columns: 33% 33% 34%;
}

.avatarMedHolder {
  position: relative;
  margin-right: 24px;
}

.avatarMedHolder img {
  display: block;
}

.avatarMedHolder .awardStar {
  position: absolute;
  top: 36px;
  right: -16px;
}

.avatarMedHolder .awardStar:after {
  content: url('../../assets/badge-star.svg');
}

.emojiAwards h4,
.attendanceAwards h4 {
  margin: 0 0 8px 0;
  font-size: 21px;
}

.emojiAwards h4 p,
.attendanceAwards h4 p {
  font-size: inherit;
  margin: 0;
}

.userMedRightSide p {
  margin: 0 0 20px 0;
  font-size: 16px;
  line-height: 1.2;
}

.textLink {
  font-family: var(--ui-font);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.textLink:hover {
  color: var(--y-purple);
}

.textLink:active {
  color: var(--y-darkpurple);
}

.contentBox p {
  font-size: 18px;
}

@media (max-width: 1000px) {
  .progress table {
    font-size: .8em;
  }
  .progress td, .progress th {
    font-size: 14px;
  }
  .progress .username {
    font-size: 16px;
    max-width: 350px;
    min-width: 150px;
    white-space: normal;
  }
  .contentBox {
    padding: 8px 20px;
  }
  .contentBox p {
    font-size: 16px;
    padding-bottom: 8px;
  }
  .members {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .contentBox h3.recognition {
    font-size: 21px;
  }
  .emojiAwards {
    grid-template-columns: 100%;
  }
  .attendanceAwards {
    grid-template-columns: 100%;
  }
  .userLargeRightSide {
    padding-right: 8px;
  }
  .userLargeRightSide h4, .userMedRightSide h4 {
    font-size: 18px;
  }
  .userLargeRightSide p, .userMedRightSide p {
    font-size: 14px;
  }
}