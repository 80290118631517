.emojiBar {
  margin-top: 10px;
}
.emojiButton {
  background: var(--y-lightgray);
  border: 1px solid var(--y-lightgray);
  border-radius: 4px;;
  margin: 2px 4px 0 0;
  padding: 3px 9px 2px 9px;
  font-size: 18px;
  text-align: center;
  vertical-align: text-top;
  position: static;
}

button.emojiButton:hover {
  background-color: var(--y-white);
  border: 1px solid var(--y-black)
}

.activeEmojiButton {
  background: var(--y-purple);
  border: 1px solid var(--y-purple);
  color: var(--y-white);
}

.activeEmojiButton:hover {
  color: var(--y-black);
}

.emojiCount {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  padding-left: 4px;
}

.emojiVotes {
  font-size: 14px;
  padding-left: 4px;
  vertical-align: text-top;
}
