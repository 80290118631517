body {
    background-color: var(--y-lightgray);
}

.content {
    color: var(--y-black);
    font-family: var(--ui-font);
    font-size: 21px;
    text-align: left;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.content .back {
    display: block;
    color: var(--y-purple);
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    margin-top: 8px;
    margin-bottom: 16px;
}

.challengeBox {
    background-color: var(--y-white);
    padding: 20px 40px 20px 40px;
    margin-bottom: 20px;
}

.challengeBox p {
    font-size: 18px;
}

.link {
    color: var(--y-purple);
    font-weight: 700;
    text-decoration: none;
}

.link * {
    color: var(--y-purple);
    text-decoration: none;
}

.imageBox {
    margin-top: 4px;
    margin-left: 60px;
    float: right;
}

.illustrationImage {
    width: 200px;
    height: 170px;
}

.content .titleBox {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: baseline;
}

.content .dayProgress {
    color: var(--y-purple);
    margin: 0;
    padding: 0;
    text-align: right;
}

.content .editLink {
    color: var(--y-purple);
    margin: 0 20px;
    padding: 0;
    font-family: var(--ui-font);
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
}

.content .editLink * {
    color: var(--y-purple);
    text-decoration: none;
}

.content h1 {
    font-family: var(--brand-font);
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 30px;
}

.content h2 {
    font-family: var(--brand-font);
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 30px;
}

.content h2.lightWeight {
    font-weight: 400;
}

.content h2.lightWeight b {
    font-weight: 500;
}

.content h2.lightWeight .link {
    font-weight: 500;
}

.content h3 {
    font-weight: 700;
    font-size: .8em;
    color: var(--y-purple);
    text-decoration: none;
    margin: 0;
}

.content .description {
    font-size: 18px;
    font-weight: 400;
    padding: 4px 0 0 0;
}

.content .highlight {
    background-color: var(--y-yellow);
}

.completionMessage {
    padding-bottom: 20px;
    padding-right: 100px;
}

.contentBox {
    background-color: var(--y-white);
    padding: 16px 40px 20px 40px;
    margin-bottom: 20px;
}

.content .message {
    display: block;
    padding: 0 0 16px 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--y-purple);
}

.content .challengeLinkHolder {
    display: flex;
    flex-flow: row nowrap;
}

.content .challengeLink {
    font-family: var(--ui-font);
    font-weight: 500;
    font-size: 18px;
    color: var(--y-black);
    background-color: var(--y-lightgray);
    border: none;
    width: 50%;
    height: 26px;
    padding: 4px;
    margin-bottom: 20px;
}

.content .challengeLink:focus {
    border: none;
    outline: none;
}

.content button.copyLink {
    width: auto;
    height: 36px;
    padding: 0 18px;
    margin: 0 0 0 16px;
    background-color: var(--y-white);
    font-family: var(--brand-font);
    font-weight: 500;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid var(--y-black);
    color: var(--y-black);
}

.content button.copyLink:hover {
    border: 1px solid var(--y-purple);
    color: var(--y-purple);
    background-color: var(--y-white);
}

.content button.copyLink:active {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
    border: 1px solid var(--y-darkpurple);
}

.content .copyMessage {
    padding: 0 0 16px 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--y-purple);
}

.content button.boldFilled {
    background-color: var(--y-purple);
    font-family: var(--brand-font);
    font-size: 28px;
    font-weight: 500;
    color: var(--y-white);
    width: 365px;
    height: 60px;
    border: none;
    border-radius: 4px;
    outline: none;
    margin: 20px 0;
}

.content button.boldFilled:hover {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
}

.content button.boldFilled:active {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
    border-color: var(--y-darkpurple);
}

.content button.dayButton {
    background: none;
    color: var(--y-purple);
    width: auto;
    padding: 0 20px;
    margin: 0;
    font-size: 32px;
}

.content button.selected {
    color: var(--y-black);
}

button.mediumBlackOutline {
    width: auto;
    min-width: 180px;
    min-height: 36px;
    padding: 4px 40px;
    background-color: var(--y-white);
    font-family: var(--brand-font);
    font-weight: 500;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid var(--y-black);
    color: var(--y-black);
    margin: 0;
    clear: both;
}

button.mediumBlackOutline:hover {
    outline: none;
    border-color: var(--y-purple);
    color: var(--y-purple);
    background-color: var(--y-white);
}

button.mediumBlackOutline:focus {
    outline: none;
    border-color: var(--y-black);
    color: var(--y-black);
}

button.mediumBlackOutline:active {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
    border-color: var(--y-darkpurple);
}

.content .columnLayout {
    display: block;
}

.content .columnLayout:after {
    content: "";
    display: table;
    clear: both;
}

.content .columnLayout .primaryButtons {
    display: flex;
    flex-flow: column wrap;
    width: 300px;
    min-height: 130px;
    float: right;
    padding-top: 0;
}

.content .columnLayout .primaryButtons h2 {
    font-size: 24px;
    margin-top: 8px;
}
.content .columnLayout .primaryButtons a {
    margin-bottom: 16px;
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
    body {
        width: 100%;
    }
    .completionMessage {
        padding-bottom: 20px;
        padding-right: 0;
    }
    .content {
        margin: 64px 0;
        padding: 0 20px;
    }
    .imageBox {
        margin-top: 16px;
        margin-left: 24px;
        float: right;
    }
    .challengeBox {
        padding: 8px 20px 20px 20px;
        margin-bottom: 12px;
    }
    .challengeBox p, .challengeBox p.description {
        font-size: 16px;
    }
    .content .titleBox {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;
    }
    .content .challengeLink {
        width: 100%;
        font-size: 14px;
    }
    .illustrationImage {
        width: 120px;
        height: 102px;
    }
    .dayProgress {
        text-align: left;
    }
    .contentBox {
        padding: 8px 20px;
    }
    .contentBox p {
        font-size: .9em;
    }
    .content h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }
    .content button.dayButton {
        font-size: 22px;
        padding: 0 10px;
        margin: 0;
    }
    .content h2, .content .columnLayout .primaryButtons h2 {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .content button.boldFilled {
        font-size: 24px;
        font-weight: 500;
        color: var(--y-white);
        width: auto;
        height: auto;
        border: none;
        border-radius: 4px;
        outline: none;
        padding: 12px 20px;
        margin: 20px 0;
    }
    .content .columnLayout {
        flex-flow: column wrap;
    }
    .content .columnLayout .primaryButtons {
        width: 100%;
        max-width: 300px;
        padding-top: 12px;
        margin-right: 0;
        margin-bottom: 20px;
        align-items: left;
        float: none;
        min-height: 0;
    }
    .content .members {
        width: calc( 100% + 20px);
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding: 0;
        margin: 0;
    }
}