.Gallery {
    color: var(--y-black);
    font-family: var(--ui-font);
    font-size: 21px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    touch-action: manipulation; /* Disable double-tap to zoom on mobile browsers */
    margin-bottom: 20px;
}

.PromptHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}

.PromptHeader a {
    text-decoration: none;
    font-weight: bold;
    margin-left: 20px;
    color: var(--y-black);
}

.topSubmissionArea {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    padding: 2em;
    background: var(--y-white);
}

.promptBox {
    position: relative;
}

.adLink {
    position: absolute;
    bottom: 0px;
}
.adLink .runningHead {
    font-family: var(--brand-font);
    font-size: 21px;
    font-weight: 400;
    margin-right: 10px;
}
.adLink .runningHead .emoji {
    font-size: 16px;
}
.adLink .runningText {
    font-size: 16px;
}
.adLink .runningText a {
    font-weight: 700;
    text-decoration: none;
    color: var(--y-purple);
}

.shareButton {
    margin-top: 16px;
}

.content .promptBox h2 {
    margin-top: 0;
    padding-top: 0;
}

.promptInstructions {
    background-color: var(--y-yellow);
}

.content {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
}

.content h1 {
    font-family: var(--brand-font);
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 40px;
    flex-grow: 2;
}

.content h2 {
    font-family: var(--brand-font);
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    padding-top: 20px;
    margin-bottom: 30px;
}

.headerSection {
    padding-bottom: 24px;
}
.content .headerSection h2 {
    margin-bottom: 20px;
}

h2.promptTitle {
    font-weight: 400;
}

.drawings {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
}

.sketchHolder {
    background: var(--y-white);
    padding: 20px;
    margin-bottom: 20px;
}
.spacerHolder {
    margin-bottom: 20px;
}

.sketch {
    padding: 0;
    margin: 0;
    border: none;
}
.sketch > * {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.authorName {
    margin: 0 0 16px 0;
    font-weight: 700;
    font-size: 18px;
}

.nextPrompt {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
}

.nextPrompt h2 {
    padding: 0;
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
    .Gallery {
        font-size: 14px;
        padding: 0 20px;
    }

    .Gallery h1 {
        font-size: 24px;
        margin-bottom: 0;
    }

    .Gallery h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }
    .PromptHeader {
        white-space: normal;
        margin-bottom: 20px;
    }
    
    .topSubmissionArea {
        grid-template-columns: 1fr;
        grid-gap: 1em;
        padding: 20px;
    }

    .adLink {
        position: relative;
        bottom: 0;
        margin-top: 16px;
    }
    .adLink .runningHead {
        font-size: 16px;
        margin-right: 6px;
    }
    .adLink .runningText {
        font-size: 14px;
    }
    .adLink .runningHead .emoji {
        font-size: 14px;
    }

    h2.promptTitle {
        padding-top: 8px;
        margin-bottom: 12px;
    }
    .headerSection {
        padding-bottom: 12px;
    }
    .content .headerSection h2 {
        padding-top: 0;
        margin-bottom: 20px;
    }
    .headerSection p {
        font-size: 14px;
    }

    .drawings {
        margin-bottom: 20px;
    }

    .nextPrompt {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 20px 0;
    }

    .nextPrompt button {
        width: 100%;
        max-width: 400px;
        padding: 0 30px;
        margin-bottom: 40px;
    }

    .authorName {
        font-size: 16px;
    }
}
