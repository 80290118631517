body {
    background-color: var(--y-lightgray);
}

.avatarLarge {
    padding: 0 32px 0 0px;
}

.pointer {
    cursor: pointer;
}

.avatarLarge img {
    width: 160px;
    height: 160px;
    border: 1px solid var(--y-medgray);
}

.mediumBlackOutline {
    display: inline-block;
    width: auto;
    min-width: 180px;
    padding: 8px;
    text-align: center;
    background-color: var(--y-white);
    font-family: var(--brand-font);
    font-weight: 500;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid var(--y-black);
    color: var(--y-black);
    margin: 16px 0 0 0;
    text-decoration: none;
    clear: both;
}

.mediumBlackOutline:hover {
    outline: none;
    border-color: var(--y-purple);
    color: var(--y-purple);
}

.mediumBlackOutline:focus {
    outline: none;
    border-color: var(--y-black);
    color: var(--y-black);
}

.mediumBlackOutline:active {
    outline: none;
    color: var(--y-white);
    background-color: var(--y-darkpurple);
    border-color: var(--y-darkpurple);
}

.content {
    color: var(--y-black);
    font-family: var(--ui-font);
    font-size: 21px;
    text-align: left;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}

.content h1 {
    font-family: var(--brand-font);
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 30px;
}

.content h2 {
    font-family: var(--brand-font);
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    padding-top: 0;
    margin: 0;
}

.content .pageHeader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.pageHeader .nameBlock {
    flex-grow: 10;
}
.nameBlock h1 {
    margin: 10px 0 10px 0;
}
.nameBlock p {
    margin-top: 0;
}

.contentBox {
    background-color: var(--y-white);
    padding: 24px 40px 24px 40px;
    margin-bottom: 20px;
}

.contentBox p.smallMessage {
    font-size: 16px;
}
.imageHolder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.name {
    font-family: var(--ui-font);
    font-size: 21px;
    margin: 0 10px 0 0;
    width: 200px;
}

.aboutYou {
    font-size: 18px;
}

.metaInfo {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
}
.socialHandle {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: var(--y-black);
    margin-right: 20px;
}
.socialHandle:visited {
    color: var(--y-black);
}
.socialHandle:active {
    color: var(--y-purple);
}
.socialIcon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.publicProfile {
    font-size: 15px;
    font-weight: 500;
    color: var(--y-darkgray);
}
.profileLink {
    color: var(--y-black);
}
.textlink {
    color: var(--y-purple);
    font-weight: 500;
    text-decoration: none;
}

.content .back {
    display: block;
    color: var(--y-purple);
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 24px;
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
    body {
        width: 100%;
    }
    .avatarLarge {
        padding: 0 16px 0 0px;
    }
    .avatarLarge img {
        width: 80px;
        height: 80px;
        border: 1px solid var(--y-medgray);
    }    
    .content .pageHeader {
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;
    }
    .content .nameBlock h1 {
        margin: 8px 0 8px 0;
    }

    .content {
        padding: 0 20px;
    }
    .content .back {
        font-size: 14px;
    }
    .contentBox {
        padding: 8px 20px;
    }
    .contentBox p {
        font-size: .9em;
    }
    .contentBox p.smallMessage {
        font-size: 16px;
    }

    .content h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }
    .content h2 {
        font-size: 22px;
        margin-top: 12px;
        margin-bottom: 20px;
    }

    .metaInfo {
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .metaInfo > * {
        margin-bottom: 4px;
    }
}