.content {
    color: var(--y-black);
    font-family: var(--ui-font);
    font-size: 21px;
    font-weight: 400;
    text-align: left;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.content h1 {
    font-family: var(--brand-font);
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 0;
}

.content p, li {
    font-size: .85em;
    line-height: 1.5em;
}

.contentBox {
    background-color: var(--y-white);
    padding: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.content a {
    color: var(--y-purple);
    text-decoration: underline;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}


 /* FAQ styles */

.accordion {
    border: none;
    margin-top: 36px;
}

.accordionButton {
    background-color: var(--y-white);
    color: var(--y-black);
    cursor: pointer;
    padding: 28px 40px;
    width: auto;
    font-family: var(--brand-font);
    font-size: 28px;
    font-weight: 400;
    text-align: left;
    border: none;
}

.accordionButton:hover {
    color: var(--y-purple);
}

.accordionButton:focus {
    color: var(--y-black);
    outline: 0;
}

/* .accordionButton[aria-expanded='true'] {
    color: var(--y-purple);
} */

[hidden] {
    display: none;
}

.accordionPanel {
    padding: 0 40px 28px 40px;
    margin-top: 0;
    /* animation: fadein 0.35s ease-in; */
    background-color: var(--y-white);
}

.accordionPanel p {
    padding-top: 0;
    margin-top: 0;
}

.accordionPanel ul {
    padding-left: 1em;
}

.accordionItem {
    margin-bottom: 12px;
}

.accordion a {
    text-decoration: none;
    border-bottom: 1px solid var(--y-purple);
}

/* -------------------------------------------------- */

/* ---------------- Animation part ------------------ */

/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
    .content {
        width: 100%;
        margin: 0;
    }
    .content .contentBox h1 {
        font-size: 28px;
        margin-top: 18px;
        margin-left: 0;
    }
    .contentBox {
        background-color: var(--y-lightgray);
        margin: 24px 0;
        padding: 8px 20px;
        font-size: 18px;
    }

    /* FAQ styles */
    .accordion {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .content h1 {
        font-size: 28px;
        margin-top: 60px;
        margin-left: 24px;
    }
    .accordionButton {
        font-size: 22px;
        padding: 24px;
    }
    .accordionPanel {
        padding: 0 24px 24px 24px;
    }
    .accordionPanel p, .accordionPanel ul {
        font-size: 18px;
    }
    .accordionPanel li {
        font-size: 18px;
    }
}