.emojiBar {
  margin-top: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.emojiButton {
  font-size: 20px;
  padding: 0 20px 0 0;
}

.activeEmojiButton {
  background: var(--y-purple);
}

.emojiCount {
  font-size: 12px;
  font-weight: 600;
  padding-left: 4px;
  vertical-align: middle;
}
