.gridColumn2 {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, calc(100% / 2));
}

.gridColumn3 {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, calc(100% / 3));
}

.gridAlignBottom {
  align-items: end; /* Make sure drawings line up in case of long names above the sketch */
}

@media (max-width: 1000px) {
  .gridColumn3{
      grid-template-columns: repeat(2, calc(100% / 2));
  }
}

ul {
  list-style: none;
  padding: 0;
}

.Modal .sketch {
  min-width: 500px;
  min-height: 500px;
}

.modalTitle {
  font-weight: 700;
  font-size: 16px;
  margin: 1em 0;
}

.Modal {
  position: fixed;
  z-index: 500;
  background-color: var(--y-lightgray);
  width: 960px;
  min-height: 620px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  padding: 16px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  display: flex;
  flex-flow: row nowrap;
}

.modalTop {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 6px;
  overflow-wrap: break-word;
}

.closeButton {
  position: fixed;
  top: 20px;
  right: 20px;
}

.closeButton .icon {
  cursor: pointer;
}

.sketchDescription {
  padding-bottom: 40px;
}

.sketchBottomInfo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
}

.sketchInfo {
  padding: 20px 20px 0 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
}

.dayLabel {
  font-family: var(--brand-font);
  font-size: 24px;
  font-weight: 500;
}

.modalLoading {
  width: 100%;
  padding: 4em;
}

.pagingButtons {
  font-size: 16px;
}

.pagingIndexCount {
  margin: 0 20px;
}

.sketch img,
.sketch canvas {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    background: var(--y-white);
    cursor: pointer;
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
  .Modal {
      position: fixed;
      z-index: 500;
      background-color: var(--y-lightgray);
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid #ccc;
      padding: 16px;
      flex-flow: column nowrap;
  }
  .modalTop {
      font-size: 12px;
      margin-bottom: 6px;
      overflow-wrap: break-word;
  }
  .closeButton {
      top: 16px;
      right: 20px;
  }
  .closeButton .icon {
      width: 18px;
      height: 18px;
  }
  
  .Modal .sketch {
      min-width: auto;
      min-height: auto;
  }
  .sketchInfo {
      padding: 0;
  }
  .sketchDescription {
      padding-bottom: 20px;
  }
}