.content {
  color: var(--y-black);
  font-family: var(--ui-font);
  font-size: 21px;
  text-align: left;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.content h1 {
  font-family: var(--brand-font);
  font-size: 40px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 30px;
}

.content h2 {
  font-family: var(--brand-font);
  font-size: 32px;
  font-weight: 500;
  text-align: left;
  padding-top: 8px;
  margin-bottom: 30px;
}

.alignTwoEndsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subheadPurple {
  color: var(--y-purple);
  font-family: var(--ui-font);
  font-weight: 400;
  font-size: 21px;
  margin-bottom: 24px;
}

.contentBox {
  background-color: var(--y-white);
  padding: 16px 40px 20px 40px;
  margin-bottom: 20px;
}

.flatButton {
  text-decoration: none;
  color: inherit;
  padding: 4px;
  margin: 4px;
}

.flatButton[disabled] {
  color: #bbb;
}

.primaryButton {
  background-color: var(--y-purple);
  font-family: var(--brand-font);
  font-size: 28px;
  font-weight: 500;
  color: var(--y-white);
  width: 365px;
  height: 60px;
  min-width: 295px;
  border: none;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.primaryButton[disabled] {
  background: #aaa;
}

.primaryButton:hover {
  outline: none;
  color: var(--y-white);
  background-color: var(--y-darkpurple);
}

.primaryButton:active {
  outline: none;
  color: var(--y-white);
  background-color: var(--y-darkpurple);
  border-color: var(--y-darkpurple);
}

.primaryButtonOutline {
  background-color: var(--y-white);
  font-family: var(--brand-font);
  font-size: 28px;
  font-weight: 500;
  color: var(--y-purple);
  width: 365px;
  height: 60px;
  min-width: 295px;
  border: 1px solid var(--y-purple);
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.primaryButtonOutline:hover {
  outline: none;
  color: var(--y-white);
  background-color: var(--y-darkpurple);
}

.primaryButtonOutline:active {
  outline: none;
  color: var(--y-white);
  background-color: var(--y-darkpurple);
  border-color: var(--y-darkpurple);
}

.secondaryButtonFill {
  width: auto;
  min-width: 160px;
  height: 36px;
  padding: 0 20px;
  background-color: var(--y-purple);
  font-family: var(--brand-font);
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  color: var(--y-white);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.secondaryButtonFill:hover {
  outline: none;
  color: var(--y-white);
  background-color: var(--y-darkpurple);
}

.secondaryButtonFill:focus {
  outline: none;
  border-color: var(--y-black);
  background-color: var(--y-white);
  color: var(--y-black);
}

.secondaryButtonFill:active {
  outline: none;
  color: var(--y-white);
  background-color: var(--y-darkpurple);
  border-color: var(--y-darkpurple);
}

.secondaryButtonPurpleOutline {
  width: auto;
  min-width: 160px;
  height: 36px;
  padding: 0 20px;
  background-color: var(--y-white);
  font-family: var(--brand-font);
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid var(--y-purple);
  color: var(--y-purple);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.secondaryButtonPurpleOutline:hover {
  outline: none;
  border-color: var(--y-darkpurple);
  background-color: var(--y-darkpurple);
  color: var(--y-white);
}

.secondaryButtonPurpleOutline:focus {
  outline: none;
  border-color: var(--y-black);
  background-color: var(--y-white);
  color: var(--y-black);
}

.secondaryButtonPurpleOutline:active {
  outline: none;
  background-color: var(--y-darkpurple);
  border-color: var(--y-darkpurple);
  color: var(--y-white);
}

.secondaryButtonOutline {
  width: auto;
  min-width: 160px;
  height: 36px;
  padding: 0 20px;
  background-color: var(--y-white);
  font-family: var(--brand-font);
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid var(--y-black);
  color: var(--y-black);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.secondaryButtonOutline[disabled] {
  opacity: 0.5;
}

.secondaryButtonOutline:hover {
  outline: none;
  border-color: var(--y-darkpurple);
  background-color: var(--y-darkpurple);
  color: var(--y-white);
}

.secondaryButtonOutline:focus {
  outline: none;
  border-color: var(--y-black);
  background-color: var(--y-white);
  color: var(--y-black);
}

.secondaryButtonOutline:active {
  outline: none;
  background-color: var(--y-darkpurple);
  border-color: var(--y-darkpurple);
  color: var(--y-white);
}

.secondaryButtonOutline {
  /* When buttons appear next to each other */
  margin-right: 12px;
}

.addVerticalSpace {
  margin: 20px 0;
}

.closeButton {
  background-image: url(./assets/icon-close.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 1.5em;
  width: 1.5em;
}

.closeButton.corner {
  position: absolute;
  top: 2em;
  right: 2em;
}

/* For smaller than 1000px */

@media (max-width: 1000px) {
  .content {
    margin: 64px 0;
    padding: 0 20px;
    font-size: 16px;
  }
  .content h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .content h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .subheadPurple {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .contentBox {
    padding: 20px;
  }
  .primaryButton {
    font-size: 24px;
    width: 100%;
    height: 52px;
    max-width: 400px;
    padding: 10px auto;
  }
  .secondaryButtonOutline {
    /* When buttons stack */
    margin-bottom: 12px;
  }  
}