/* .Container { */
    /* This approach to fix the scrolling doesn't work because the Prompt parent container still scrolls with your touches */
    /* position: fixed;
    top: 50%;
    transform: translateY(-50%); */
/* } */

.drawingToolButtons {
    margin: 18px 0;
    padding: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
}