
.content {
  color: var(--y-black);
  font-family: var(--ui-font);
  font-size: 21px;
  font-weight: 400;
  margin: 5em 2em 2em;
}

.tabs {
  display: flex wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
}

.tabs button {
  color: var(--y-black);
  text-transform: capitalize;
  font-family: var(--ui-font);
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  background: var(--y-white);
  margin: 0 8px 8px 0;
}

.content h2 {
  font-family: var(--brand-font);
  font-size: 40px;
  font-weight: 500;
}

.content h2 a {
  font-size: 1.5rem;
  margin-left: 2em;
}

.content h3 {
  font-size: 1em;
}

.content table {
  width: 100%;
}

.content th {
  text-align: left;
}
.content th, td {
  padding: 4px 4px;
  font-size: .75em;
  vertical-align: text-top;
}

.content td.tiny {
  font-size: .6em;
}

.content td.fixedWidth {
  width: 20%;
}

.content tr:nth-child(odd) {
  background: #fffa;
}

.content a {
  margin-right: 0.5em;
}

.content tr.expandedChallenge {
  background: #ddd;
}

.content button {
  background-color: var(--y-lightgray);
  border: 1px solid var(--y-darkgray);
  border-radius: 4px
}
.content button:hover {
  background-color: var(--y-white);
  border: 1px solid var(--y-black);
}

.content tr:target {
  animation: highlight forwards 3s;
  scroll-margin-top: 6em;
}

.content .avatar {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  vertical-align: baseline;
}

ul {
  margin: 0;
}

@keyframes highlight {
  0% {background: orange;}
  80% {background: orange;}
  100% {}
}

.popupContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 90vh;
  z-index: 10;
  background: var(--y-white);
  padding: 1em;
  box-shadow: 0 0 0 30em #000c;
}

.closeButton {
  float: right;
}


/* For smaller than 1000px */
@media (max-width: 1000px) {
  .content {
    margin: 60px 20px;
  }
}