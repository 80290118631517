.content {
  color: var(--y-black);
  font-family: var(--ui-font);
  font-size: 21px;
  text-align: left;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  overflow: auto;
}

.content h1 {
  font-family: var(--brand-font);
  font-size: 36px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 32px;
}

.content h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 28px;
}

.content .host {
  display: static;
  vertical-align: middle;
  font-size: .65em;
  font-weight: 700;
  background-color: var(--y-yellow);
  padding: 2px 4px;
  margin-left: 2px;
}

.content .layout {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.content .infoBox {
  order: 2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.content .infoBox .link {
  text-decoration: none;
  color: var(--y-purple);
  font-size: .9em;
  font-weight: 600;
  margin-top: 8px;
}

.infoBox .challengeImage {
  width: 150px;
  height: 131px;
}

/* .content .form {
  width: 100%;
} */

.content .field {
  display: grid;
  grid-template-columns: 110px 400px auto;
  column-gap: 4px;
  margin: 0 0 16px 0;
  /* width: 100%; */
}

.content .titleHint {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.titleHint .hintMessage {
  padding-right: 12px;
  flex-shrink: 100;
}

.hintMessage {
  font-size: 16px;
  line-height: 140%;
  margin: 8px 0 0 0;
}

.content .formLabel {
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 16px;
}

.content input {
  font-family: var(--ui-font);
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 4px 8px;
  height: 36px;
}

.content textarea.multiLine {
  font-family: var(--ui-font);
  font-size: 18px;
  font-weight: 500;
  margin: 0 10px 0 0;
  width: 90%;
  height: 120px;
  padding: 8px 8px;
}

.field input:not([type=checkbox]) {
  display: block;
  width: 90%;
}

select,
.date {
  width: 100%;
  margin: 0 15px 0 0;
}

select {
  padding: 12px 6px;
}

.timezone {
  display: flex;
  align-items: center;
}

.timezone input {
  margin-right: .5em;
}

.promptsAdmin {
  display: block;
  margin-bottom: 1em;
}

.promptsAdmin input:not([type=checkbox]) {
  display: block;
  width: 100%;
}

.promptsAdmin input.parameterInput {
  width: auto !important;
  flex: .5;
  margin: 0 8px;
  font-size: 0.9em;
}

.promptsCreator {
  display: none;
  visibility: hidden;
}

.content button {
  width: auto;
  height: 36px;
  padding: 4px 20px;
  background-color: var(--y-white);
  font-family: var(--brand-font);
  font-weight: 500;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid var(--y-black);
  color: var(--y-black);
}

.content button.purple {
  border: 1px solid var(--y-purple);
  color: var(--y-purple);
  margin: 10px 0px;
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
}

.content button.purple:hover {
  outline: none;
  border-color: var(--y-darkpurple);
  color: var(--y-darkpurple);
}

.content button.purple:focus {
  outline: none;
}

.content button.purple:active {
  outline: none;
  color: var(--y-white);
  background-color: var(--y-darkpurple);
  border-color: var(--y-darkpurple);
}

.content .buttonHolder {
  display: flex;
  flex-flow: row wrap;
}

.content button.action {
  background-color: var(--y-purple);
  font-family: var(--brand-font);
  font-size: 28px;
  font-weight: 500;
  color: var(--y-white);
  width: 356px;
  height: 54px;
  padding-bottom: 3px;
  border-radius: 4px;
  border: 1px solid var(--y-purple);
  outline: none;
  margin: 12px 0;
  overflow: hidden;
  white-space: nowrap;
}

.content button.action:hover {
  border: 1px solid var(--y-darkpurple);
  outline: none;
  background-color: var(--y-darkpurple);
}

.content button.action:active {
  border: 1px solid var(--y-darkpurple);
  outline: none;
  color: var(--y-white);
  background-color: var(--y-darkpurple);
  border-color: var(--y-darkpurple);
}

.content button.purpleOutlineLarge {
  background-color: var(--y-white);
  font-family: var(--brand-font);
  font-size: 26px;
  font-weight: 500;
  color: var(--y-purple);
  width: 120px;
  height: 54px;
  padding: 3px 20px 3px 20px;
  border-radius: 4px;
  border: 1px solid var(--y-purple);
  outline: none;
  margin: 12px 16px 12px 0;
}
.content button.purpleOutlineLarge:hover {
  border: 1px solid var(--y-darkpurple);
  color: var(--y-darkpurple);
  outline: none;
}
.content button.purpleOutlineLarge:active {
  border: 1px solid var(--y-darkpurple);
  outline: none;
  color: var(--y-white);
  background-color: var(--y-darkpurple);
  border-color: var(--y-darkpurple);
}


.message {
  padding: 30px 20px 20px 28px;
  font-size: .9em;
}

.challengeLink {
  display: inline-block;
  color: var(--y-purple);
  font-weight: 600;
  text-decoration: none;
  padding-left: 16px;
}

.challengeLink * {
  color: var(--y-purple);
  text-decoration: none;
}

.promptListItem {
  display: flex;
  align-items: center;
}

.promptListItem label {
  flex: 1;
}

.promptsAdmin input[type=checkbox] {
  margin: 0 8px 0 0;
  vertical-align: top;
}

.promptsAdmin .promptItem {
  vertical-align: text-top;
}

.orderButton[disabled] {
  opacity: .5;
}

.forceDisplay {
  margin-bottom: 4px;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: 7em 1fr;
  grid-gap: 1em;
  align-items: center;
  margin-bottom: 2em;
}

.fieldsContainer label {
  display: contents;
}

.fieldsContainer label *:first-child {
  font-weight: 600;
  font-size: .9em;
}

/* For smaller than 900px */
@media (max-width: 1000px) {
  .content {
    padding: 20px 20px;
  }
}
@media (max-width: 900px) {
  .content h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .content h2 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .content .layout {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
  }
  .content .form {
    order: 2;
  }
  .content .infoBox {
    order: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .infoBox .challengeImage {
    width: 113px;
    height: 98px;
  }
  /* .content .field {
    grid-template-rows: auto auto auto;
    row-gap: 4px;
    width: 100%;
  } */
  .content .field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%; 
    /* calc( 100% - 18px ); */
    margin-bottom: 12px;
  }
  .content .field>* {
    margin-bottom: 4px;
  }
  .content .titleHint {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
  .hintMessage {
    font-size: 14px;
  }
  .content button.action {
    width: auto;
    max-width: 300px;
    padding: 0 24px;
    margin-bottom: 16px;
    font-size: 21px;
    height: 52px;
  }
  .content button.purpleOutlineLarge {
    width: auto;
    max-width: 120px;
    padding: 0 20px;
    margin-bottom: 16px;
    font-size: 21px;
    height: 52px;
  }

  .challengeLink {
    padding-left: 0;
  }
}